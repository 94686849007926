import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tag } from 'antd';
import { getStock } from '../../../../appRedux/actions/Stock';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import {
  ModalView,
  RenderStatus,
  ListTable,
  TableFilters,
} from '../../../uiComponents';
import { StockDetailModalBody } from '../StockDetailModalBody';
import { StockAdjustmentModalBody } from '../StockAdjustmentModalBody';
import { MaxMinModalBody } from '../MaxMinModalBody';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Stock.const';
import { stringDate } from '../../../../util/formatTime';
import { StockTableOpts } from '../StockTableOpts';
import moment from 'moment/moment';
import { StockByBranchReport } from '../../Reports/StockByBranchReport';
import { roleConst } from '../../../../constants';

export const StockTable = () => {
  const {
    role,
    branchId,
    privileges: { inventory: privileges = [] },
  } = useSelector((state) => state?.userAuth);
  const { modal } = useCustomModal();
  const [modalTitle, setModalTitle] = useState(null);
  const [children, setChildren] = useState(null);

  const getModal = useCallback(
    (data, action = null) => {
      modal();

      switch (action) {
        case 'adjustment':
          setChildren(
            <StockAdjustmentModalBody data={data} branchId={branchId} />
          );
          setModalTitle('AJUSTE DE INVENTARIO');
          break;
        case 'max/min':
          setChildren(<MaxMinModalBody data={data} />);
          setModalTitle('Máximos y minimos');
          break;
        default:
          setChildren(
            <StockDetailModalBody
              data={data}
              role={role}
              privileges={privileges}
            />
          );
          setModalTitle(data.product?.name);
          break;
      }
    },
    [modal, branchId, role, privileges]
  );

  const showTableOptions = (data) => {
    if (data.distribution_center) {
      return false;
    }

    return (
      <StockTableOpts
        action={{
          onEdit: {
            getModal,
            params: [data, 'adjustment'],
          },
          setMaxMin: {
            getModal,
            params: [data, 'max/min'],
          },
        }}
        privileges={privileges}
      />
    );
  };

  const addRowClass = ({ quantity, max, min, expiresIn }) => {
    if (quantity <= 0) return;

    if (expiresIn) {
      const expirationDates = expiresIn.split(',');
      for (const expirationDate of expirationDates) {
        const diffInDays = moment(expirationDate).diff(moment(), 'days');
        if (diffInDays < 30) return 'row-danger';
      }
    }

    if ((max > 0 && quantity > max) || (min > 0 && quantity < min))
      return 'row-volcano';
  };

  const columns = [
    {
      title: 'SKU',
      sorter: true,
      dataIndex: 'sku',
      key: 'sku',
      render: (sku, data) => (
        <Button type="link" htmlType="button" onClick={() => getModal(data)}>
          {sku}
        </Button>
      ),
    },
    {
      title: 'Producto',
      sorter: true,
      dataIndex: 'product',
      key: 'product',
      render: (product) => product,
    },
    {
      title: 'Fecha de expiración',
      sorter: true,
      dataIndex: 'expiresIn',
      key: 'expiresIn',
      render: (expiresIn, row) => {
        if (!expiresIn) return '-';

        if (Number(row?.quantity) > 0) {
          return expiresIn?.split(',').map((date) => {
            return <Tag key={Math.random()}>{stringDate(date)}</Tag>;
          });
        } else {
          return null;
        }
      },
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch,
    },
    {
      title: 'Cantidad',
      sorter: true,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => quantity,
    },
    {
      title: 'Máximo',
      sorter: true,
      dataIndex: 'max',
      key: 'max',
      render: (max) => (max ? max : '0'),
    },
    {
      title: 'Mínimo',
      sorter: true,
      dataIndex: 'min',
      key: 'min',
      render: (min) => (min ? min : '0'),
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <RenderStatus data={{ status }} />,
    },
    {
      title: 'Opciones',
      key: 'opc',
      render: (product) => showTableOptions(product),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        role={role}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        standOut={true}
        rowClass={addRowClass}
        name={'Inventario'}
        component={role === roleConst.branchManager && <StockByBranchReport />}
        getDataFunction={getStock}
      />
      <ModalView title={modalTitle} width={1000}>
        {children}
      </ModalView>
    </>
  );
};
