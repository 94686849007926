import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { BranchAppliancesTable } from '../../components/BranchAppliances/BranchApplianceTable';
import { useSelector } from 'react-redux';

export const BranchAppliances = () => {
  const navigate = useNavigate();
  const {
    privileges: { appliances: privileges = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      {privileges.includes('create') && (
        <Button
          type="primary"
          onClick={() => {
            navigate('/dashboard/aparatos-sucursal/vincular');
          }}
        >
          <PlusOutlined />
          Vincular
        </Button>
      )}
      {privileges.includes('create') && (
        <Button
          type="ghost"
          className="btn-primary-outline"
          onClick={() => {
            navigate('/dashboard/aparatos');
          }}
        >
          Aparatos
        </Button>
      )}

      <BranchAppliancesTable />
    </Card>
  );
};
