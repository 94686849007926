import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getStockDetails } from '../../../../appRedux/actions/Stock';
import { stringDate } from '../../../../util/formatTime';
import { formatPrice } from '../../../../util/formatPrice';
import moment from 'moment';
import { roleConst, stockConst } from '../../../../constants';

export const StockDetailModalBody = ({ data, role }) => {
  const dispatch = useDispatch();
  const { branchId, productId } = data;
  const { stockDetails } = useSelector((state) => state.stock);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    dispatch(getStockDetails(branchId, productId));
  }, [dispatch, branchId, productId]);

  useEffect(() => {
    if (stockDetails.length) {
      let quantity = 0;
      for (const stock of stockDetails) {
        quantity += Number(stock.quantity);

        if (quantity === 0) {
          setDetails([]);
        } else {
          setDetails(stockDetails);
        }
      }
    } else {
      setDetails([]);
    }
  }, [stockDetails]);

  const addRowClass = ({ expiresIn }) => {
    const days = moment(expiresIn).diff(moment(), 'days');

    if (days <= 0) {
      return stockConst.EXPIRATION.DANGER;
    } else if (days <= 30) {
      return stockConst.EXPIRATION.WARNING;
    } else if (days <= 60) {
      return stockConst.EXPIRATION.ALERT;
    } else if (days > 60) {
      return stockConst.EXPIRATION.OKAY;
    }
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'stockId',
      key: 'stockId',
    },
    {
      title: 'Sucursal',
      dataIndex: 'branch',
      key: 'branch',
    },
    {
      title: 'Lote',
      dataIndex: 'allotment',
      key: 'allotment',
    },
    {
      title: 'Fecha de expiración',
      dataIndex: 'expiresIn',
      key: 'expiresIn',
      render: (expiresIn) => stringDate(expiresIn),
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  if (role !== roleConst.branchManager && role !== roleConst.franchisee)
    columns.push(
      {
        title: 'Precio',
        dataIndex: 'price',
        key: 'price',
        render: (price) => formatPrice(price),
      },
      {
        title: 'Total',
        dataIndex: 'price',
        key: 'price.quantity',
        render: (price, { quantity }) => formatPrice(price * quantity),
      }
    );

  return (
    <Table dataSource={details} columns={columns} rowClassName={addRowClass} />
  );
};
